import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { SurveyWriteS1Grid ,SurveyWriteHeader } from '@survey/components'
import api from '@survey/api'
import { mapActions, mapGetters } from 'vuex'
import * as types from '@survey/store/types'

@Component({
    components: {
        SurveyWriteS1Grid,
        SurveyWriteHeader
    },
    methods: {
        ...mapActions({
            setSurveyId: types.SURVEY_CREATED_SURVEYID
        })
    },
    computed: {
        ...mapGetters({
            createdSurveyId: types.SURVEY_CREATED_SURVEYID
        })
    }
})
export default class SurveyWriteS1View extends Vue {

    setSurveyId!: (surveyId?: number) => void
    createdSurveyId!: number

    surveyRequest: survey.SurveyRequest = Object.create(null)
    isPopup : boolean = false
     

    created() {
       //팝업인지 확인
       this.isPopup = this.$route.path.toLowerCase().includes('popup')

        this.setPage({
            
            title: this.$t('설문작성').toString()
        })

        
    }



    updateSurveyInfo(data: survey.SurveyRequest) {

        this.surveyRequest = data
    }



    validation() {

        try {
            if (this.surveyRequest == null ||
                //타이틀입력안한경우
                this.surveyRequest.mainInfo.title == "" ||
                //설명입력안한경우
                this.surveyRequest.mainInfo.description == "") {
                throw new Error()
            }

        } catch (err) {
            return false
        }

        return true
    }



    async save() {
        //유효성 체크
        if (this.validation()) {

            if (this.surveyRequest.mainInfo.isTargetAll) {
                this.surveyRequest.targets = []
            }
            //MENUID 추가
            this.surveyRequest.mainInfo.menuId = parseInt(<string>this.$route.query.MENUID)
            
            api.survey.insertSurveyMainInfo(this.surveyRequest).then(async result => {
                if (result.success) {
                    //return surveyId
                    this.setSurveyId(result.surveyId)
                    //다음 step 페이징 이동
                    this.$router.push({
                        name: this.isPopup ? "SurveyPopupWriteStep02" : "SurveyWriteStep02",
                        query: { ...this.$route.query,
                            surveyId: result.surveyId.toString() 
                        }
                    })
                }
                //저장 실패
                else {
                    await (window as any).alertAsync(this.$t('저장_실패').toString())
                }
            })
        } else {
            
            await (window as any).alertAsync(this.$t('빈_값_확인').toString())
        }
    }

    goBack() {
        if(this.isPopup){
            window.close()
        }else{
            this.$router.push({
                name: "SurveyList",
                query: {
                    MENUID : this.$route.query.MENUID
                }
            })
        }

    }

    async goList(){
        
        if(await(window as any).confirmAsync(this.$t('저장하지_않고_목록으로_이동하면_작성_중_상태가_유지_됩니다_').toString())){
            this.goBack()
        }
    }



    //취소 과정에서 모든 데이터 삭제 처리
    //작성 중 삭제이기 때문에 flag 처리 아님
    async deleteHard() {
        if (await(window as any).confirmAsync(this.$t('저장하지않고_취소').toString())) {

            if (typeof (this.createdSurveyId) == 'object') {
                this.goBack()
                return
            }

            api.survey.deleteHardSurvey(this.createdSurveyId).then(async result => {
                if (result) {
                    this.setSurveyId()
                    this.goBack()
                } else {
                    await (window as any).alertAsync(this.$t('설문_삭제_실패').toString())
                }


            })
        }
    }

    async rightNowPostring(){
        if(await(window as any).confirmAsync(this.$t('바로게시___설문시작일이_현재로_변경됨').toString())){

        }
    }
}
